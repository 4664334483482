import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "name",
    "vat",
    "address",
    "city",
    "zip",
    "country",
    "magnifier",
    "continueBtn",
    "vatAuthority",
    "companyActivity"
  ]

  connect() {
    this.handleMagnifier()
  }

  addResultToForm(e) {
    this.nameTarget.value = e.target.dataset.name
    this.nameTarget.dispatchEvent(new Event("input"))
    this.addressTarget.value = e.target.dataset.address
    this.cityTarget.value = ""
    this.zipTarget.value = ""
    if (e.target.dataset.country == "Greece") {
      this.cityTarget.value = e.target.dataset.city
      this.zipTarget.value = e.target.dataset.zip
    }
  }

  handleMagnifier() {
    this.magnifierTarget.style.visibility = "hidden"
    this.magnifierTarget.classList.remove("text-info")
    this.magnifierTarget.setAttribute("data-bs-original-title", this.magnifierTarget.dataset.disabledTooltipText)
    const selectedCountry = this.countryTarget.nodeName == "SELECT" ?
      this.countryTarget[this.countryTarget.selectedIndex].text : "Greece"
    if (this.viesCountries().includes(selectedCountry) && this.vatTarget.value.length >= 8) {
      this.magnifierTarget.style.visibility = "visible"
      this.magnifierTarget.classList.add("text-info")
      this.magnifierTarget.setAttribute("data-bs-original-title", this.magnifierTarget.dataset.enabledTooltipText)
    } else if (this.viesCountries().includes(selectedCountry)) {
      this.magnifierTarget.style.visibility = "visible"
      this.magnifierTarget.classList.remove("text-info")
    }
  }

  viesCountries() {
    let countries = JSON.parse(this.magnifierTarget.dataset.viesCountries)
    countries.push("Greece")
    return countries
  }

  search() {
    if (this.magnifierTarget.classList.contains("text-info")) {
      this.magnifierTarget.hidden = true
      let autocomplete = false
      let accept = "text/javascript"
      if (this.magnifierTarget.dataset.autocomplete) { autocomplete = true }
      document.querySelector(".spinner-border").hidden = false
      const selectedCountry = this.countryTarget.nodeName == "SELECT" ?
        this.countryTarget[this.countryTarget.selectedIndex].text : "Greece"

      if (this.magnifierTarget.dataset.clearSearchResultBtn) {
        // If button is "x" / clear results then update fields with empty data and exit
        this.updateFields('clear')
        return true
      }

      fetch(this.magnifierTarget.dataset.url + `?country=${selectedCountry}&vat=${this.vatTarget.value}`, {
        method: "POST",
        headers: {
          "X-CSRF-Token": this.csrfToken(),
          "Accept": "application/json"
        }
      }).then((response) => response.json())
        .then((data) => {
          if (data.valid) {
            this.updateFields(data)
          } else {
            this.updateFields(false)
          }
        })

    }
  }

  updateFields(data) {
    if (data.valid) {
      this.nameTarget.value = data.name
      this.addressTarget.value = data.address
      this.cityTarget.value = data.city
      this.zipTarget.value = data.zip
      if (this.hasVatAuthorityTarget && data.vat_authority) {
        this.vatAuthorityTarget.value = data.vat_authority
      }
      if (this.hasCompanyActivityTarget && data.activity) {
        this.companyActivityTarget.value = data.activity
      }
      this.magnifierTarget.classList.replace('fa-search', 'fa-circle-xmark')
      this.magnifierTarget.dataset.bsOriginalTitle = this.magnifierTarget.dataset.clearSearchResultTooltipText
      this.magnifierTarget.dataset.clearSearchResultBtn = true
    } else if (data === 'clear') {
      this.nameTarget.value = ""
      this.addressTarget.value = ""
      this.cityTarget.value = ""
      this.zipTarget.value = ""
      if (this.hasVatAuthorityTarget) { this.vatAuthorityTarget.value = "" }
      if (this.hasCompanyActivityTarget) { this.companyActivityTarget.value = "" }
      this.magnifierTarget.classList.replace('fa-circle-xmark', 'fa-search')
      this.magnifierTarget.dataset.bsOriginalTitle = this.magnifierTarget.dataset.disabledTooltipText
      delete this.magnifierTarget.dataset.clearSearchResultBtn
    } else {
      notification('alert', this.magnifierTarget.dataset.noResultsMsg)
    }
    ['nameTarget', 'addressTarget', 'cityTarget', 'zipTarget', 'vatAuthorityTarget', 'companyActivityTarget'].forEach((target) => {
      const exist_property = target.charAt(0).toUpperCase() + target.slice(1)
      if (this[exist_property]) {
        this[exist_property].parentElement.hidden = true
      }
    })
    // this.nameTarget.parentElement.hidden = false
    // this.addressTarget.parentElement.hidden = false
    // this.cityTarget.parentElement.hidden = false
    // this.zipTarget.parentElement.hidden = false
    // this.vatAuthorityTarget.parentElement.hidden = false
    // this.companyActivityTarget.parentElement.hidden = false
    document.querySelector(".spinner-border").hidden = true
    this.magnifierTarget.hidden = false
    this.handleContinue()
  }

  handleEnter(e) {
    // Handle Enter
    if (e.code === "Enter") {
      e.preventDefault()
      const selectedCountry = this.countryTarget.nodeName === "SELECT" ?
        this.countryTarget[this.countryTarget.selectedIndex].text : "Greece"
      if (this.viesCountries().includes(selectedCountry) && this.vatTarget.value.length >= 8) {
        this.search()
      }
    }
  }

  handleVatChange(e) {
    if (this.magnifierTarget.dataset.clearSearchResultBtn) {
      this.magnifierTarget.classList.replace('fa-circle-xmark', 'fa-search')
      this.magnifierTarget.dataset.bsOriginalTitle = this.magnifierTarget.dataset.disabledTooltipText
      delete this.magnifierTarget.dataset.clearSearchResultBtn
    }
  }

  csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  handleContinue() {
    if (this.hasContinueBtnTarget) {
      this.continueBtnTarget.disabled = !this.requiredInputs()
    }
  }

  requiredInputs() {
    return this.vatTarget.value.length > 0 && this.nameTarget.value.length > 0 &&
      this.cityTarget.value.length > 0 && this.zipTarget.value.length > 0
  }
}
