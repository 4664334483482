import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    "country", 
    "hiringType", 
    "greeceOption", 
    "foreignOption", 
    "fulltimeForm", 
    "freelanceForm",
    "companyForm",
    "soleProprietorshipForm"
  ]

  connect() {
    this.countryChange(false)
  }

  countryChange(resetHiringType=true) {
    this.hiringTypeTarget.closest(".row").hidden = this.countryTarget.value == ""
    this.setHiringTypeOptions(resetHiringType)
  }

  setHiringTypeOptions(resetHiringType) {
    const countryText = this.countryTarget.options[this.countryTarget.selectedIndex].text
    if ( resetHiringType ) { this.hiringTypeTarget.value = "" }
    this.hiringTypeChange()
    this.greeceOptionTargets.map(option => option.hidden = countryText != "Greece" )
    this.foreignOptionTargets.map(option => option.hidden = countryText == "Greece" )
  }

  hiringTypeChange() {  
    this.fulltimeFormTarget.disabled = !["permanent_fulltime", "temporary_fulltime"]
      .includes(this.hiringTypeTarget.value)
    this.fulltimeFormTarget.hidden = !["permanent_fulltime", "temporary_fulltime"]
      .includes(this.hiringTypeTarget.value)
    this.freelanceFormTarget.disabled = this.hiringTypeTarget.value != "freelance"
    this.freelanceFormTarget.hidden = this.hiringTypeTarget.value != "freelance"
    this.companyFormTarget.disabled = this.hiringTypeTarget.value != "company"
    this.companyFormTarget.hidden = this.hiringTypeTarget.value != "company"
    this.soleProprietorshipFormTarget.disabled = this.hiringTypeTarget.value != "sole_proprietorship"
    this.soleProprietorshipFormTarget.hidden = this.hiringTypeTarget.value != "sole_proprietorship"
  }

}