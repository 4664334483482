import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["deliveryField"]
  static values = {
    isDelivery: Boolean, deliveryNoteTitle: String
  }
  connect() {
    this.presentValue()
  }

  presentValue() {

    // for (const element of this.deliveryFieldTargets) {
    //   if (element.value) {
    //     this.isDeliveryValue = true
    //     break
    //   } else {
    //     this.isDeliveryValue = false
    //   }
    // }

    if (this.deliveryFieldTargets.filter(field => field.value).length > 0) {
      this.isDeliveryValue = true
    } else {
      this.isDeliveryValue = false
    }
    let is_delivery_note = document.querySelector('#invoice_invoice_type').value === 'delivery_note'
    if (is_delivery_note) {
      let product_name = document.querySelector('.js-line-name')
      let submitBtn = document.querySelector('[type="submit"][name="issue_and_send"]')
      let  draftBtn = document.querySelector('[name="create_draft"]')
      submitBtn.disabled = !this.isDeliveryValue || !product_name.value
      draftBtn.disabled = !this.isDeliveryValue || !product_name.value
    } else {
      let el = document.querySelector('.page-heading h1')
      if (this.isDeliveryValue && !el.textContent.includes(this.deliveryNoteTitleValue)) {
        el.textContent += this.deliveryNoteTitleValue
      } else if (!this.isDeliveryValue) {
        el.textContent = el.textContent.replace(this.deliveryNoteTitleValue,"")
      }

    }

    // let el = document.querySelector('.page-heading h1')
    // if (this.isDeliveryValue && !el.textContent.includes(this.deliveryNoteTitleValue)) {
    //   el.textContent += this.deliveryNoteTitleValue
    // } else if (!this.isDeliveryValue) {
    //   el.textContent = el.textContent.replace(this.deliveryNoteTitleValue,"")
    // }
  }
}
