import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["serviceDropdown"]
  static values = { serviceLines: Object, invoiceType: String }

  setElements() {
    const elements = document.querySelectorAll(".js-service-line-data")
    this.serviceLinesValue = {}
    elements.forEach(elem => {
      const lineData = {
        id: elem.dataset.id,
        name: elem.dataset.name,
        nameLatin: elem.dataset.nameLatin,
        price: elem.dataset.price,
        vatRate: elem.dataset.vatRate,
        vatExemptionCode: elem.dataset.vatExemptionCode,
        vatExemptionCodeTitle: elem.dataset.vatExemptionCodeTitle,
        code: elem.dataset.code,
        quantityType: elem.dataset.quantityType,
        description: elem.dataset.description
      }
      this.serviceLinesValue = { ...this.serviceLinesValue, [elem.dataset.id]: lineData }
    })
  }

  handleNameChanged(event) {
    const value = event.target.value
    const serviceType = event.target.dataset.serviceType
    if (value.length == 0) { this.clearServiceLineId(event.target.closest(".js-line")) }
    this.searchServiceLines(value, serviceType)
    let el = document.querySelector('#invoice_recipient_of_movement')
    if (el) document.querySelector('#invoice_recipient_of_movement').dispatchEvent(new Event('input'))
  }

  clearServiceLineId(line) {
    line.querySelector("input[id$=service_line_id]").value = ""
  }

  searchServiceLines(searchTerm, serviceType = "") {
    const url = event.target.dataset.searchUrl + ".json" + `?search=${searchTerm}&${eval(serviceType).map((st) => { return "search_type[]=" + st }).join("&")}`

    if (searchTerm.length > 2) {
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          this.serviceLinesValue = {}
          data.map(el => {
            const lineData = {
              id: el.id,
              name: el.name,
              nameLatin: el.nameLatin,
              price: el.price,
              vatRate: el.vatRate,
              vatExemptionCode: el.vatExemptionCode,
              vatExemptionCodeTitle: el.vatExemptionCodeTitle,
              code: el.code,
              quantityType: el.quantityType,
              description: el.description
            }
            this.serviceLinesValue = { ...this.serviceLinesValue, [el.id]: lineData }
          })
          this.refreshDropdown()
        })
    } else {
      this.serviceDropdownTarget.innerHTML = ""
      this.hideDropdown()
    }
  }

  refreshDropdown() {
    this.serviceDropdownTarget.innerHTML = ""
    Object.entries(this.serviceLinesValue).forEach(line => {
      this.serviceDropdownTarget.appendChild(this.createDropdownElement(line))
    })
    if (this.serviceDropdownTarget.innerHTML) {
      this.showDropdown()
    }
  }

  hideDropdown() {
    if (!this.serviceDropdownTarget.classList.contains("show")) return
    this.serviceDropdownTarget.classList.remove("show")
  }

  hideDropdownTimeout() {
    if (!this.serviceDropdownTarget.classList.contains("show")) return
    setTimeout(() => { this.hideDropdown() }, 200)
  }

  showDropdown() {
    if (this.serviceDropdownTarget.classList.contains("show")) return
    this.serviceDropdownTarget.classList.add("show")
  }

  focusName(event) {
    if (!this.serviceDropdownTarget.innerHTML || event.target.value == "") this.setElements()
    this.refreshDropdown()
  }

  createDropdownElement(data) {
    const elem = document.createElement("div")
    elem.innerHTML = `<div class="service-name fw-bold pointer-events-none">${data[1].name}</div>`
    if (Number(data[1].price) > 0) {
      elem.innerHTML += `<div class="service-price fw-light pointer-events-none">€ ${data[1].price}</div>`
    }
    elem.dataset.price = data[1].price
    elem.dataset.name = data[1].name
    elem.dataset.vatRate = data[1].vatRate
    elem.dataset.vatExemptionCode = data[1].vatExemptionCode
    elem.dataset.vatExemptionCodeTitle = data[1].vatExemptionCodeTitle
    elem.dataset.code = data[1].code || ""
    elem.dataset.quantityType = data[1].quantityType || ""
    elem.dataset.description = data[1].description || ""
    elem.dataset.id = data[1].id
    elem.classList.add("dropdown-item")
    elem.classList.add("cursor-pointer")
    elem.setAttribute("data-action", "click->invoice-line-service#serviceClicked")
    return elem
  }

  serviceClicked(e) {
    this.hideDropdown()
    let line_item_name = ".js-line-name",
      event_type = "input",
      unit_price = ".js-line-unit-price",
      code = ".js-line-code"

    this.element.querySelector(line_item_name).value = e.target.dataset.name
    this.element.querySelector(code).value = e.target.dataset.code
    this.element.querySelector("select[id$=quantity_type]").value = e.target.dataset.quantityType
    this.element.querySelector("input[id$=description]").value = e.target.dataset.description
    this.element.querySelector("input[id$=service_line_id]").value = e.target.dataset.id

    const unitPriceElem = this.element.querySelector(unit_price)
    unitPriceElem.value = e.target.dataset.price
    if ( !["title_deed", "title_deed_refusal"].includes(this.invoiceTypeValue) ) {
      this.setVatAndExemption(this.element, e.target)
    }
    const trigger = new CustomEvent("handle-submit-btn");
    window.dispatchEvent(trigger);
  }

  setVatAndExemption(line, service) {

    if (['rent_income', 'self_delivery', 'self_use'].includes(document.getElementById("invoice_invoice_type").value)) { return }
    
    const country = document.getElementById("invoice_account_id").dataset.country
    const serviceVatRate = service.dataset.vatRate
    const serviceExemptionCode = service.dataset.vatExemptionCode
    const serviceExemptionTitle = service.dataset.vatExemptionCodeTitle
    let vat_rate_selector = ".js-line-vat-rate"
    let vat_exemption_selector = "[id*=vat_exemption_code]"

    const lineVatRateSelect = line.querySelector(vat_rate_selector)
    const lineVatExemptionSelect = line.querySelector(vat_exemption_selector)

    if ((serviceVatRate && country && country == "Greece") || (serviceVatRate && !country)) {
      this.setSelected(lineVatRateSelect, serviceVatRate, serviceVatRate)
      if (serviceVatRate == '0') this.setSelected(lineVatExemptionSelect, serviceExemptionCode, serviceExemptionTitle)
    } else if (serviceVatRate && country && country != "Greece") {
      this.setSelected(lineVatRateSelect, "0", "0")
      this.setSelected(lineVatExemptionSelect, "4", lineVatExemptionSelect.dataset.zeroVatExemptionTitle)
    }
  }

  setSelected(elem, value, text) {
    const options = Array.from(elem.querySelectorAll("option"))
      .map((option) => { return option.value })
      .filter((a) => { return a != "" })

    if (!options.includes(value)) {
      let newOption = document.createElement("option")
      newOption.value = value
      newOption.innerHTML = text
      elem.appendChild(newOption)
    }
    elem.value = value
    elem.dispatchEvent(new Event("input"))
  }

}
