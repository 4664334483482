import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['newTable']

  static values = {
    tenantId: String
  }
  connect() {
  }

  createNewTable(e) {
    e.preventDefault()
    console.log('clicked')
    console.log(this.tenantIdValue)
  }
}
