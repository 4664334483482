import { Controller } from "@hotwired/stimulus"
import $ from 'jquery';


export default class extends Controller {
  static targets = ["posOptions", "posSelection"]
  static values = {
    defaultPos: String,
    allowedPosPayments: Boolean,
    idsToShowOnPaymentProcess: Array,
    idsToHideOnPaymentProcess: Array
  }

  initialize() {
    this.setPaymentProcess(false)
  }
  connect() {
    this.handlePaymentMethod()
  }

  // based on whether pos payment method is rendered in select2 or not
  // if not then the select div should have a class of payment-radio
  paymentMethodVal() {
    if (document.querySelector('.payment-radio')) {
      return document.querySelector('.payment-radio:checked').value
    } else {
      const paymentMethod = $('#paymentMethod')
      return paymentMethod.find(':selected')[0].value
    }
  }

  handlePaymentMethod() {
    if (this.allowedPosPaymentsValue && this.hasPosSelectionTarget && this.paymentMethodVal() == 'card') {
      this.posOptionsTarget.classList.remove('d-none')
      this.posSelectionTarget.value = this.defaultPosValue
    } else {
      this.posOptionsTarget.classList.add('d-none')
      this.posSelectionTarget.value = null
    }
  }

  paymentInProcess() {
    if (this.hasPosSelectionTarget && this.posSelectionTarget.value) {
      this.updateCountdownPaymentBar();
      if (document.getElementById('modalXBtn')) {
        document.getElementById('modalXBtn').disabled = true
      }
      this.setPaymentProcess(true)
    }
  };

  updateCountdownPaymentBar() {
    const div = document.getElementById("countdownPaymentBar");
    const val = Number(div.getAttribute('aria-valuenow'))
    if (val > 0) {
      div.setAttribute('aria-valuenow', val - 1)
      const max = div.getAttribute('aria-maxvalue', val)
      div.style.width = String(((val - 1) / max) * 100) + '%';
      setTimeout(() => {
        this.updateCountdownPaymentBar();
      }, 1000);
    }
  }

  setPaymentProcess(paymentInProcess) {
    if (paymentInProcess) {
      this.idsToShowOnPaymentProcessValue.forEach((id) => {
        document.getElementById(id).classList.remove("d-none")
      })
      this.idsToHideOnPaymentProcessValue.forEach((id) => {
        document.getElementById(id).classList.add("d-none")
      })
    } else {
      this.idsToShowOnPaymentProcessValue.forEach((id) => {
        document.getElementById(id).classList.add("d-none")
      })
      this.idsToHideOnPaymentProcessValue.forEach((id) => {
        document.getElementById(id).classList.remove("d-none")
      })
    }
  }
}
