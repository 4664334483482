import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["addressTitleField"]
  connect() {
  }

  handleTitleChange(e) {
    let el = e.target.closest('div').querySelector('span')
    if (e.target.value.length > 0) {
      el.classList.add('d-none')
    } else {
      el.classList.remove('d-none')
    }
    if (e.target.classList.contains('is-invalid')) this.validateFields()
  }

  validateSubmission(e) {
    this.validateFields() ? true : e.preventDefault()
  }

  validateFields() {
    let result = true
    if (this.hasAddressTitleFieldTarget) {
      this.addressTitleFieldTargets.forEach( (title) => {
        if (title.value.length === 0) {
          title.classList.add('is-invalid')
          result = false
        } else {
          title.classList.remove('is-invalid')
        }
      })
    }
    return result
  }
}
